import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.scss';
import './Advanced.scss';
import './css/font-awesome.min.css';
// import Navbar from './components/Navbar';
// import Home from './pages/Home';
// import Footer from './components/Footer';
// import Gallery from './pages/Gallery';
// import Contact from './components/Contact';
// import Prices from './pages/Prices';
// import ContactUs from './components/ContactUs';
// import NotFound from './pages/NotFound';
// import createBrowserHistory from 'history/createBrowserHistory';
import asyncComponent from './AsyncComponent';
import { ScrollToTop } from './components/ScrollToTop';
import Loadable from 'react-loadable';


const Navbar = asyncComponent(() =>
    import('./components/Navbar').then(module => module.default)
)
const MobileMenu = asyncComponent(() =>
    import('./components/MobileMenu').then(module => module.default)
)
// const Home = asyncComponent(() =>
//     import('./pages/Home').then(module => module.default)
// )
const Footer = asyncComponent(() =>
    import('./components/Footer').then(module => module.default)
)
const Gallery = asyncComponent(() =>
    import('./pages/Gallery').then(module => module.default)
)
const Contact = asyncComponent(() =>
    import('./components/Contact').then(module => module.default)
)
const Prices = asyncComponent(() =>
    import('./pages/Prices').then(module => module.default)
)
const ContactUs = asyncComponent(() =>
    import('./components/ContactUs').then(module => module.default)
)
const NotFound = asyncComponent(() =>
    import('./pages/NotFound').then(module => module.default)
)

const AsyncHome = Loadable({
    loader: () => import('./pages/Home'),
    loading: NotFound,
    delay: 5000
})

// const history = createBrowserHistory();

export default class Routers extends Component {
    render() {
        return (
            <Router>
            {/* <Router history={history}> */}
                <ScrollToTop>
                    <Navbar />
                    <MobileMenu id="mobile-menu-container" className="mobile-menu-container" />
                    <Switch>
                        <Route exact path="/" component={AsyncHome}/>
                        <Route path="/galeria" component={Gallery}/>
                        <Route path="/cennik" component={Prices}/>
                        <Route path="/kontakt" component={Contact}/>
                        <Route path="*" component={NotFound}/>
                    </Switch>
                    <ContactUs />
                    <Footer />
                </ScrollToTop>
            </Router>
        )
    }
}
